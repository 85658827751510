<template>
    <b-container>
        <b-container class="py-0">
            <p class="txt_secondary text-uppercase pt-3"><strong>Información de general</strong></p>
            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody> 
                            <b-tr>
                                <b-th class="th_style">Procedimiento programado</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr> 
                            <b-tr>
                                <b-th class="th_style">Procedimiento realizado</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Intervencionista</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr> 
                            <b-tr>
                                <b-th class="th_style">Ayudante</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Anestesiologo</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <p class="txt_secondary text-uppercase pt-3"><strong>Horario</strong></p>
            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody> 
                            <b-tr>
                                <b-th class="th_style">Ingreso</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                                <b-th class="th_style">Inicio</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                                <b-th class="th_style">Termino</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                                <b-th class="th_style">Egreso</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <p class="txt_secondary text-uppercase pt-3"><strong>Signos vitales</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Frecuencia cardiaca</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-on:keypress="soloNumeros($event)" maxlength="5" type="text" name="FC" autocomplete="off" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Temperatura</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-on:keypress="soloNumeros($event)" maxlength="5" type="text" name="temperatura" autocomplete="off" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">°C</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style size_th_frecuencias">Tensión arterial</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <div>
                                            <b-form-input type="text" name="pa1" autocomplete="off" maxlength="3" v-on:keypress="soloNumeros($event)" class="text-center border-0" size="sm" placeholder="#"/>
                                        </div>
                                        <div class="txt_gray px-1">/</div>
                                        <div>
                                            <b-form-input type="text" name="pa2" autocomplete="off" maxlength="3" v-on:keypress="soloNumeros($event)" class="text-center border-0" size="sm" placeholder="#"/>
                                        </div>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody> 
                            <b-tr>
                                <b-th class="th_style">Frecuencia respiratoria</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-on:keypress="soloNumeros($event)" maxlength="5" type="text" name="FR" autocomplete="off" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr> 
                            <b-tr>
                                <b-th class="th_style">SPO2</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input v-on:keypress="soloNumeros($event)" maxlength="5" type="text" name="SPO2" autocomplete="off" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">%</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <p class="txt_secondary text-uppercase pt-3"><strong>Registro de signos vitales</strong></p>
            <b-row>
                <b-col cols="12">
                    <b-table-simple responsive class="table-sm mt-3 table-responsive">
                        <b-thead>
                            <b-tr>
                                <b-th class="th_style signos_vitales text-center">Hora</b-th>
                                <b-th class="th_style signos_vitales text-center">Temp</b-th>
                                <b-th class="th_style signos_vitales text-center">F.R.</b-th>
                                <b-th class="th_style signos_vitales text-center">F.C.</b-th>
                                <b-th class="th_style signos_vitales text-center">T.A.</b-th>
                                <b-th class="th_style signos_vitales text-center">SPO2</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>  
                            <b-tr>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <p class="txt_secondary text-uppercase pt-3"><strong>Soluciones</strong></p>
            <b-row>
                <b-col cols="12">
                    <b-table-simple responsive class="table-sm mt-3 table-responsive">
                        <b-thead>
                            <b-tr>
                                <b-th class="th_style text-center">Soluciones</b-th>
                                <b-th class="th_style text-center">Hora</b-th>
                                <b-th class="th_style text-center">Ingreso ML</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>  
                            <b-tr>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <p class="txt_secondary text-uppercase pt-3"><strong>Medicamentos</strong></p>
            <b-row>
                <b-col cols="12">
                    <b-table-simple responsive class="table-sm mt-3 table-responsive">
                        <b-thead>
                            <b-tr>
                                <b-th class="th_style text-center">Medicamento</b-th>
                                <b-th class="th_style text-center">Hora</b-th>
                                <b-th class="th_style text-center">Ingreso ML</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>  
                            <b-tr>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-table-simple responsive class="table-sm mt-3 table-responsive">
                        <b-thead>
                            <b-tr>
                                <b-th class="th_style text-center">Balón o stent</b-th>
                                <b-th class="th_style text-center">Atmósferas</b-th>
                                <b-th class="th_style text-center">Insuflación</b-th>
                                <b-th class="th_style text-center">Vaso sanguíneo</b-th>
                                <b-th class="th_style text-center">Tiempo</b-th>
                                <b-th class="th_style text-center">Dolor precordial</b-th>
                                <b-th class="th_style text-center">Requirio marcapazos</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>  
                            <b-tr>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                                <b-td class="border_table text-center">
                                    <span class="size-span">123</span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <p class="txt_secondary text-uppercase pt-3"><strong>Nota de enfermeria</strong></p>
            <b-row>
                <b-col cols="12">
                    <b-form-textarea autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="5" max-rows="5" />
                </b-col>
            </b-row>
            <div class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                        :show="busy"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'registroHemodinamia',
    components:{ },
    data:() => ({
        busy: false,
        paciente:{},
    }),

    methods: {
        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
}
</script>

<style scoped>
    @media only screen and (min-width: 768px) {
        .signos_vitales {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @media only screen and (max-width: 1220px) {
        .signos_vitales {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
</style>